import React, { FC } from 'react'
import cx from 'clsx'
import SafeLink from '@ignition/library/components/atoms/safe-link'

export interface GoToProps {
  name: string
  url: string
  className: string
}

const classes = {
  a: 'text-primary text-sm hover:text-secondary',
}

export const ReadMoreLink: FC<GoToProps> = ({ name, url, className }) => {
  return (
    <span className={cx('inline-flex', className)}>
      <SafeLink id={url} href={`/${url}`}>
        <span className={classes.a}>{name}</span>
      </SafeLink>
    </span>
  )
}
