import React, { FC } from 'react'
import cx from 'clsx'
import { ReadMoreLink } from '@/components/atoms/read-more-link'
import ImageElement from '@/components/elements/image'
import { TeaserProps } from '@/types/site.interface'
import SafeLink from '@ignition/library/components/atoms/safe-link'
import Text from '@ignition/library/components/atoms/text'

const classes = {
  container: 'w-full flex flex-col justify-between overflow-hidden rounded-lg shadow-lg',
  contentContainer: 'flex flex-1 flex-col justify-between bg-white p-6 h-full',
  imageContainer: 'flex-shrink-0 w-full aspect-video relative overflow-hidden',
  caption: 'text-sm font-medium text-primary hover:underline',
  title: 'text-xl font-semibold text-gray-900 mt-2',
  summary: 'mt-3 text-base text-gray-500',
  category: '',
  readMore: 'text-primary hover:text-secondary text-sm mt-2 justify-end p-6',
}

const Teaser: FC<TeaserProps> = ({ id, className, caption, title, slug, summary, image }) => {
  return (
    <div className={cx(classes.container, className)}>
      <SafeLink id={`grid-${id}`} href={`/${slug}`}>
        <>
          <div className={classes.imageContainer}>
            <ImageElement image={image} fill useFallbackImage />
          </div>
          <div className={classes.contentContainer}>
            <div>
              <Text className={classes.caption}>{caption}</Text>
              <Text className={classes.title}>{title}</Text>
              <Text as="span" className={classes.summary}>
                {summary}
              </Text>
            </div>
          </div>
        </>
      </SafeLink>
      <ReadMoreLink name={'Weiter lesen →'} url={slug} className={classes.readMore} />
    </div>
  )
}
export default Teaser
